<template>
    <icon-base viewBox="0 0 21.364 18.089" :width="size + 'px'" :fill="color" class="icon-close-session">
        <path id="Path_1964" data-name="Path 1964" d="M359.5,90.228a9,9,0,0,0-8.418,5.735.838.838,0,0,0,.473,1.087.841.841,0,0,0,1.086-.474,7.368,7.368,0,1,1,0,5.392.838.838,0,0,0-1.548-.028.827.827,0,0,0-.011.64A9.044,9.044,0,1,0,359.5,90.228Z" transform="translate(-347.183 -90.228)"/>
        <path id="Path_1965" data-name="Path 1965" d="M361,99.081l-3.2-3.2a.838.838,0,0,0-1.186,1.186l1.772,1.772h-9.586a.838.838,0,1,0,0,1.676h9.585l-1.771,1.771a.838.838,0,1,0,1.186,1.185l3.2-3.2a.866.866,0,0,0,.109-.133.748.748,0,0,0,.041-.073.556.556,0,0,0,.033-.067.955.955,0,0,0,.036-.11l.012-.044A.837.837,0,0,0,361,99.081Z" transform="translate(-347.956 -90.632)"/>
    </icon-base>
</template>

<script>
export default {
    name: 'iconCloseSession',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>

<style scoped>

#Path_1965 {
    /* transform: translate(-347.956 -90.632);
    -moz-transform: translate(-347.956 -90.632); */
}
</style>
