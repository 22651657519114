<template>
    <div>
        <div>
            <h4 class="d-flex align-items-center mb-2 weight-600">{{$t("account.personal_information")}} 
                <a class="d-flex align-items-center size-15 ms-3 weight-500 hover-red" href="#" @click.prevent="focusName">
                    <icon-edit class="me-2" :size="15" :color="'var(--blue-600)'"/>
                    {{$t('checkout.edit')}}
                </a>
            </h4>
            <p class="color-main-navy">{{$t("account.description_personal_infomation")}}.</p>
        </div>
        <form class="animation-opacity row ms-0 ms-md-3" @submit.prevent="">
            <div class="col-md-6 col-xl-5 mb-3">
                <main-input :value="form.first_name" :max="99" :title="$t('auth.name')" id="first-name" ref="name" @changeValue="data => {form.first_name = data}" placeholder="auth.enter_your_name" :readonly="readonly" @activeInput="readonly = false" :disabled="readonly">
                    <icon-person :size="15" :color="'var(--dark-gray-200)'"/>
                </main-input>
            </div>
            <div class="col-md-6 col-xl-5 mb-3">
                <main-input :value="form.last_name" :max="99" :title="$t('auth.last_name')" id="last-name" ref="lastName" @changeValue="data => {form.last_name = data}" placeholder="auth.enter_your_last_name" :readonly="readonly" @activeInput="readonly = false" :disabled="readonly">
                    <icon-last-name :size="15" :color="'var(--dark-gray-200)'"/>
                </main-input>
            </div>
            <div class="col-md-6 col-xl-5 mb-3">
                <main-input :value="form.email" ref="email" :type="'email'" :title="$t('auth.email')" id="email" @changeValue="data => {form.email = data}" placeholder="example@gmail.com" :readonly="readonly" @activeInput="readonly = false" :disabled="readonly">
                    <icon-email :size="16" :color="'var(--dark-gray-200)'"/>
                </main-input>
            </div>
            <!-- <div class="col-md-6 col-xl-5 mb-3">
                <main-input :value="form.phone" ref="phone" mask="+1 ###-###-####" :title="$t('auth.phone_number')" type="phone" id="phone" @changeValue="data => {form.phone = data}" placeholder="+1 000-000-0000" :readonly="readonly" @activeInput="readonly = false" :disabled="readonly">
                    <icon-phone :size="15" :color="'var(--dark-gray-200)'"/>
                </main-input>
            </div> -->
            <div class="col-md-6 col-xl-5 mb-3">
                <span class="title-14 mb-0" >{{ $t('auth.phone_number') | textFormat }}</span>
                <VuePhoneNumberInput :disabled="readonly" class="vue-phone-number-input" style='position: relative; top: 6px;' :valid-color="color" :translations="translations" :default-country-code="countryCode" :border-radius="7" :error="isValidPhone == false" ref="phone" id="phone" v-model="form.phone" @update="handlePhoneNumberInput" />
            </div>
            <div class="col-md-6 col-xl-5 mb-3" @click="readonly = false">
                <span class="title-15 mb-0">{{$t('account.date_of_birth')}}</span>
                <date-picker v-model="form.birth_date" valueType="format" placeholder="1993-29-05" class="mt-2 w-100" :class="{'date-picker': readonly}" :clearable="false">
                    <template #icon-calendar>
                        <icon-date :size="15" :color="'var(--dark-gray-200)'"/>
                    </template>
                </date-picker>
            </div>
            <div class="col-md-6 col-xl-5 mb-3">
                <span class="title-15">{{$t("account.gender")}}</span>
                <main-select :selectedOption="selectedOption" @clickHandler="(data) => selectedOption = data" @click.native="readonly = false" :options="filterOptions" summaryRef="select-gender" :class="{'disable': readonly}">
                    <icon-gender class="me-2" :color="'var(--dark-gray-200)'"/>
                </main-select>
            </div>
            <div class="col-md-6 col-xl-5 mb-3">
                <main-input id="code" :title="$t('auth.code')" :value="userData.entity.code" :readonly="readonly" @activeInput="readonly = false" :disabled="true">
                    <icon-code :size="17" :color="'var(--dark-gray-200)'"/>
                </main-input>
            </div>
            <div class="col-md-6 col-xl-5 mb-3">
                <main-input id="code" :title="$t('product.level_price')" :value="userData.customer.price_level_id" :readonly="readonly" @activeInput="readonly = false" :disabled="true">
                    <icon-code :size="17" :color="'var(--dark-gray-200)'"/>
                </main-input>
            </div>
            <div class="col-md-10 d-flex justify-content-end mt-2">
                <main-button id="save" title="account.save_changes" @click="save" :disabled="changed"/>
            </div>
        </form>
        <new-email :user="updateEmail" @closeModal="emailChanged = false" v-if="emailChanged"/>
    </div>
</template>

<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import MainInput from '../../../../common/components/inputs/MainInput.vue'
import IconPerson from '../../../../common/svg/iconPerson.vue'
import MainSelect from '../../../../common/components/selects/MainSelect.vue'
import IconEmail from '../../../auth/assets/svg/iconEmail.vue'
import IconDate from '../../assets/svg/iconDate.vue'
import IconLastName from '../../../auth/assets/svg/iconLastName.vue'
import IconEdit from '../../../checkout/assets/svg/iconEdit.vue'
import IconGender from '../../assets/svg/iconGender.vue'
import IconCode from '../../assets/svg/iconCode.vue'
import DatePicker from 'vue2-datepicker';
import { PhoneNumberUtil } from 'google-libphonenumber';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { mapGetters } from "vuex"
import NewEmail from './NewEmail.vue'
import 'vue2-datepicker/index.css';

export default {
    components: { MainInput, IconPerson, MainButton, NewEmail, MainSelect, IconEmail, IconDate, IconLastName, IconEdit, IconGender, IconCode, DatePicker, VuePhoneNumberInput},
    name: "UserForm",
    data() {
        return {
            changed: true,
            originalForm: null,
            form: {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                birth_date: null,
                sex: null,
                level: null,
            },
            readonly: true,
            updateEmail: null,
            emailChanged: false,
            focusCounter: null,
            isValidPhone: null,
            countryCode: this.verifyUSTECH() ? 'US' : 'DO',
            color: 'yellowgreen',
            translations:{
                countrySelectorLabel: this.$t('auth.country_code'),
                countrySelectorError: this.$t('auth.selected_country'),
                phoneNumberLabel: this.$t('auth.phone_number'),
                example: this.$t('auth.example')
            },
            filterOptions: [
                {
                    value: 'OTROS',
                    title: 'account.other'
                },
                {
                    value: 'M',
                    title: 'account.male'
                },
                {
                    value: 'F',
                    title: 'account.feminine'
                }
            ],
            selectedOption: { value: 'OTROS', title: 'account.other'},
        }
    },
    computed: {
        ...mapGetters({
            userData: "auth/userData",
        }),
    },
    watch: {
        form: {
            handler(val) {
                this.changed = JSON.stringify(val) === JSON.stringify(this.originalForm);
            },
            deep: true
        },
        selectedOption(new_){
            this.form.sex = new_.value;
        }
    },
    methods: {
        handlePhoneNumberInput(value){
            this.isValidPhone = value.isValid
            this.detectCountry(value.nationalNumber, value.countryCode)
        },
        detectCountry(phone, country) {
            this.color = 'yellowgreen'
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const number = phoneUtil.parse(phone, country);
                const code = phoneUtil.getRegionCodeForNumber(number);
                if(code != null){
                    this.countryCode = code
                } else {
                    this.color = 'orangered'
                    this.isValidPhone = false
                }
            } catch (error) {
                console.log('Error al determinar el país del número de teléfono:', error);
            }
        },
        async save(){
            this.$refs.name.$v.$touch();
            this.$refs.lastName.$v.$touch();
            this.$refs.email.$v.$touch();
            if (this.$refs.name.$v.$invalid || this.$refs.lastName.$v.$invalid || this.$refs.email.$v.$invalid){
                return;
            }
            if(!this.isValidPhone){
                return;
            }
            try {  
                if(this.userData.entity.company_email != this.form.email) {
                    const res = await this.$store.dispatch("account/updateEmail", this.form);
                    this.updateEmail = res.data.data;
                    this.emailChanged = true;
                    this.readonly = true;
                    return;
                }
                await this.$store.dispatch("account/update", this.form);
                let notify = {
                    text: 'account.notification.user_updated',
                    type: 'success',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                this.readonly = true;
                this.changed = true;
                this.originalForm = {...this.form};
            } catch (error) {
                console.log(error);
                let notify = {
                    text: 'account.notification.user_updated',
                    type: 'danger',
                    show: true,
                }
                this.$store.commit("common/SET_SHORT_NOTIFY", notify)
            }
        },
        focusName(){
            this.readonly = false;
            this.focusCounter = setImmediate(() => {
                this.$refs.name.$el.querySelector(".input-group").querySelector("input").focus();
            });
        },
    },
    created(){
        let name = this.userData.entity.first_name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        let last_name = this.userData.entity.last_name.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));

        this.form.first_name = name;
        this.form.last_name = last_name;
        this.form.phone = this.$options.filters.phoneFormat(this.userData.entity.phone);
        this.form.email = this.userData.entity.company_email;
        this.form.birth_date = this.userData.entity.birth_date || '1999-07-12';
        // this.from.price_level_id = this.from.price_level_id = this.userData.customer.price_level_id

        this.filterOptions.map((option) => {
            if (option.value === this.userData.entity.sex) 
            this.selectedOption = option;
        });
        this.form.sex = this.selectedOption.value;
        this.originalForm = {...this.form};
        
    },
    mounted(){
        document.querySelector('.vue-phone-number-input input').style.minHeight = '36px';
        document.querySelector('.vue-phone-number-input input').style.height = '38px';
        // document.querySelector('.vue-phone-number-input input').style.borderColor = '#dae5ea';

        document.querySelector('.vue-phone-number-input .input-tel__input').style.minHeight = '36px';
        document.querySelector('.vue-phone-number-input .input-tel__input').style.height = '38px';
        // document.querySelector('.vue-phone-number-input .input-tel__input').style.width = '143px';
        // document.querySelector('.vue-phone-number-input .input-tel__input').style.borderColor = '#dae5ea';

    },
    destroyed() {
        clearImmediate(this.focusCounter);
    }  
}
</script>

<style scoped>
    .select-container {
        height: 38px;
        border-radius: 7px;
        border-color: #cedde9;
        margin-top: 7px;
        padding-left: 0.6rem;
    }
    .main-input::v-deep > .input-group > input:disabled, .main-input::v-deep > .input-group > input::placeholder {
        color: var(--dark-gray-200);
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input {
        border-color: #cedde9;
        color: var(--main-navy);
        border-radius: 7px;
        height: 38px !important;
        padding: 0px;
        padding-left: 40px;
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input::placeholder{
        color: var(--dark-gray-200);;
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > .mx-icon-calendar{
        left: 10px;
    }
    .date-picker.mx-datepicker::v-deep > .mx-input-wrapper > input{
        color:var(--dark-gray-200);
    }
    .select-container::v-deep > div > span{
        color: var(--main-navy);
        opacity: 1 !important;
    }
    .disable.select-container::v-deep > div > span{
        color: var(--dark-gray-200) !important;
    }
    .hover-red > svg{
        transition: var(--transition-1);
    }
</style>
