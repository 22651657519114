<template>
    <icon-base :width="size + 'px'" :fill="color">
        <g id="Layer_21">
            <g>
                <g>
                    <ellipse class="st0" cx="125" cy="220.6" rx="124.5" ry="10.2"/>
                    <g class="st1">
                        <path class="st2" d="M204.7,93.4l-18.1-18.1c-1.9-1.9-5.1-1.9-7,0c-0.9,0.9-1.5,2.2-1.5,3.5s0.5,2.6,1.5,3.5l9.7,9.7h-53.7      c-2.7,0-5,2.2-5,5c0,2.7,2.2,5,5,5h53.7l-9.7,9.7c-1.9,1.9-1.9,5.1,0,7c0.9,0.9,2.2,1.5,3.5,1.5s2.6-0.5,3.5-1.5l18.1-18.1      c0.2-0.2,0.5-0.5,0.6-0.8c0.1-0.1,0.1-0.2,0.2-0.4l0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.2-0.4,0.2-0.6      c0-0.1,0.1-0.2,0.1-0.3C206.4,96.3,205.9,94.6,204.7,93.4z"/>
                        <g class="st3">
                            <path class="st4" d="M148.7,181.1H59c-8.3,0-15.1-6.8-15.1-15.1V40.2c0-8.3,6.8-15.1,15.1-15.1h89.8c8.4,0,15.1,6.8,15.1,15.1       v28c0,2.9-2.3,5.2-5.2,5.2s-5.2-2.3-5.2-5.2v-28c0-2.6-2.1-4.8-4.8-4.8H59c-2.6,0-4.8,2.1-4.8,4.8V166c0,2.6,2.1,4.8,4.8,4.8       h89.8c2.6,0,4.8-2.1,4.8-4.8v-39.6c0-2.9,2.3-5.2,5.2-5.2s5.2,2.3,5.2,5.2V166C163.9,174.4,157.1,181.1,148.7,181.1z"/>
                        </g>
                        <path class="st2" d="M97.9,194.8l-43-13.8c-6.6-2.1-11.1-8.3-11.1-15.2V39.5c0-7.9,7.7-13.6,15.3-11.2L98,40.5l9.5,3.3      c5.2,1.8,8.7,6.7,8.7,12.3V182C116.2,191.4,106.8,198,97.9,194.8z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <path class="st5" d="M144.4,152.9c0-22.9,18.4-41.5,41.1-42.1c2.8-8.6,4.6-17.3,4.6-26.1c0-36.1-29.2-65.5-65.1-65.5       S59.9,48.6,59.9,84.7c0,51.4,59,104.4,61.5,106.6c1,0.9,2.3,1.4,3.6,1.4c1.3,0,2.6-0.5,3.6-1.4c0.9-0.8,9.5-8.5,19.9-20.4       C145.9,165.5,144.4,159.4,144.4,152.9z M125,120.4c-19.9,0-36.2-16.2-36.2-36.2S105,48,125,48c19.9,0,36.2,16.2,36.2,36.2       S144.9,120.4,125,120.4z"/>
                        </g>
                        <g>
                            <circle class="st6" cx="186.5" cy="152.9" r="34.1"/>
                        </g>
                    </g>
                    <path class="st7" d="M202.1,163.9l-11-11l11-11c0.6-0.6,0.9-1.2,1-1.9c0.2-1-0.2-2.1-0.9-2.8c-1.2-1.2-3.3-1.2-4.5,0l-11.2,11.2     l-11.2-11.2c-1.2-1.2-3.3-1.2-4.5,0c-0.7,0.7-1.1,1.8-0.9,2.8c0.1,0.7,0.5,1.3,1,1.9l11,11l-11,11c-0.6,0.6-0.9,1.2-1,1.9     c-0.2,1,0.2,2.1,0.9,2.8s1.8,1.1,2.8,0.9c0.7-0.1,1.3-0.5,1.9-1l11-11l11,11c0.6,0.6,1.2,0.9,1.9,1c0.2,0,0.4,0,0.5,0     c0.9,0,1.7-0.3,2.3-0.9c0.7-0.7,1.1-1.8,0.9-2.8C203,165.1,202.7,164.5,202.1,163.9z"/>
                </g>
            </g>
        </g>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconDeleteAddress',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>

<style scoped>
    .st0{fill:#F0F4F9;}
    .st1{display:none;}
    .st2{display:inline;fill:#4979B2;}
    .st3{display:inline;}
    .st4{fill:#212E3D;}
    .st5{fill:#4979B2;}
    .st6{fill:#BC2C35;}
    .st7{fill:#F8FAFB;}
</style>