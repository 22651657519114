<template>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <div :class="{'no-click' : verifyEmployee }" class="upload-photo position-relative" data-toggle="tooltip" data-placement="top" :title="$t('auth.change_picture')" @click="uploadMenu = true" id="upload-menu">
            <template v-if="userData.entity.photo_url == null">
                <icon-profile :size="80" class="mb-3 position-relative" style="z-index: 1;"/>
                <input type="file" id="hidden-input" name="profile" class="input-file cursor-pointer w-100 h-100" @change="hiddenInput">
            </template>
            <template v-else>
                <div class="position-relative">
                    <img v-show="load" class="profile-image mb-3 cursor-pointer" :key="imageKey" :src="userData.entity.photo_url || '../../../../common/svg/iconPerson.vue'" 
                    @load="load = true" alt="profile">
                </div>
                <skeleton v-show="!load" class="profile-image mb-3 cursor-pointer" />
            </template>
            <div v-if="loading.image" class="loading-mask position-absolute d-flex align-items-center justify-content-center">
                <half-circle-spinner :animation-duration="1000" :size="30" color="var(--blue-600)" />
            </div>
            <div class="icon-camera-container d-flex justify-content-center cursor-pointer">
                <icon-camera :size="15" />
                <div class="position-relative">
                    <upload-profile-pic v-if="uploadMenu && userData.entity.photo_url != null" @removeImage="removePhoto" @upload="uploadPhoto" />
                </div>
            </div>
        </div>
        <h4 class="user-name title-18 mb-0 text-center text-capitalize" style="max-width: 180px;">{{userData.entity | fullName}}</h4>
        <div class="d-flex">
            <span class="d-block color-dark-gray-200 size-15 me-1 text-limited" style="max-width: 240px;">{{userData.entity.company_email}}</span>
            <icon-check-circle :size="15" />
        </div>
        <hr class="w-100">
        <ul class="color-dark-gray-200 m-0 size-14">
            <li class="d-flex align-items-center" :class="[active == option.value ? 'active-tap' :'', index > 0 ? 'pt-3' : '']" @click="$emit('select', option.value)" v-for="(option, index) in options" :key="option.value">
                <component :is="option.icon" :color="'var(--dark-gray-200)'" class="me-3" :size="option.size" :class="[option.icon === 'icon-addresses' ? 'center-icon' : '']" :style="{'margin-left':option.left+'px'}"></component>
                {{$t(option.title)}}
            </li>
        </ul>
        <hr class="w-100">
        <div>
            <div class="invisible" style="height: 0;">
                <icon-person :color="'var(--dark-gray-200)'" class="me-2" :size="17"/>{{$t("account.personal_information")}}
            </div>
            <button v-if="!mobile" :disabled="verifyEmployee" class="d-flex align-items-center button-logout" @click="$emit('logout')">
                <icon-close-session class="me-2" :color="'var(--blue-600)'"/>
                {{$t("layout.account.logout")}}
            </button>
        </div>
    </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
import { mapGetters } from "vuex"
import IconPerson from '../../../../common/svg/iconPerson.vue'
import IconAddresses from '../../../layout/assets/svg/iconAddresses.vue'
import IconPassword from '../../../auth/assets/svg/iconPassword.vue'
import IconProfile from '../../assets/svg/iconProfile.vue'
import IconCloseSession from '../../../../common/svg/iconCloseSession.vue'
import IconCheckCircle from '../../assets/svg/iconCheckCircle.vue'
import IconCamera from '../../assets/svg/iconCamera.vue'
import Skeleton from '../../../../common/components/skeleton/Skeleton.vue'
import UploadProfilePic from '../popups/UploadProfilePic.vue'

export default {
    name: 'MenuProfile',
    data() {
        return {
            uploadMenu: false,
            options: [
                {
                    value: 'information',
                    title: 'account.personal_information',
                    icon: 'icon-person',
                    size: 17
                },
                {
                    value: 'password',
                    title: 'auth.password',
                    icon: 'icon-password',
                    size: 13,
                    left:2,
                },
                {
                    value: 'address',
                    title: 'account.main_address',
                    icon: 'icon-addresses',
                    size: 20
                },
            ],
            selectedTap:'',
            imageKey: 0,
            form:{
                file: null,
                first_name: null,
                last_name: null, 
                email: null, 
                phone: null,
                price_level_id: null,
            },
            load: false,
            loading: {
                image: false
            }
        }
    },
    props: {
        active: {
            type: String
        },
        verifyEmployee: { 
            type: Boolean,
            default: false
        }
    },
    components: {
        IconPerson,
        IconPassword,
        IconAddresses,
        IconProfile,
        IconCloseSession,
        IconCheckCircle,
        IconCamera,
        Skeleton,
        UploadProfilePic,
        HalfCircleSpinner
    },
    computed: {
        ...mapGetters({
            userData: "auth/userData",
            addressSelected: "account/addressSelected",
            mobile: 'common/mobile',
        }),
    },
    watch: {
        uploadMenu(visible){
            if (visible) {
                document.addEventListener('click', this.clickOutside);
                if (this.userData.entity.photo_url == null) {
                    const input_file = document.querySelector("#hidden-input");
                    input_file.click();
                }
            }else {
                document.removeEventListener('click', this.clickOutside);
            }
        }
    },
    methods: {
        async hiddenInput(event) {
            this.uploadMenu = false;
            this.updateUserData(event.target.files[0]);
        },
        async uploadPhoto(event) {
            this.uploadMenu = false;
            this.updateUserData(event.files[0]);
        },
        async removePhoto(){
            this.updateUserData('delete');
        },
        async updateUserData(files){
            let formdata = new FormData();
            formdata.append('photo', files);
            formdata.append('first_name', this.form.first_name);
            formdata.append('last_name', this.form.last_name);
            formdata.append('email', this.form.email);
            formdata.append('phone', this.form.phone);
            // formdata.append('level', this.form.price_level_id)
            try {
                this.loading.image = true;
                await this.$store.dispatch("account/update", formdata);
            } catch (error) {
                console.log(error);
            }finally {
                this.loading.image = false;
                this.imageKey += 1
            }
        },
        clickOutside(evt) {
            const el = document.getElementById("upload-menu");
            if (!el || !(el == evt.target || el.contains(evt.target)) || this.userData.entity.photo_url == null) this.uploadMenu = false;
        }
    },
    created(){
        // this.from.price_level_id = this.userData.customer.price_level_id
        this.form.first_name = this.userData.entity.first_name;
        this.form.last_name = this.userData.entity.last_name;
        this.form.phone = this.$options.filters.phoneFormat(this.userData.entity.phone);
        this.form.email = this.userData.entity.company_email;
        this.form.birth_date = this.userData.entity.birth_date || '1999-07-12';
    },
    destroyed() {
        document.removeEventListener('click', this.clickOutside);
    },
}
</script>

<style scoped>
    .no-click {
        pointer-events: none;
    }
    .button-logout {
        color: var(--main-navy);
        background: transparent;
        border: 0;
        padding: 0;
        transition: var(--transition-1);
    }
    .loading-mask {
        top: -1px;
        left: -1px;
        width: 82px;
        height: 82px;
        border-radius: 3.125rem;
        background-color: #ffffffc2;
        transition: var(--transition-1);
        animation: appear 0.3s;
        z-index: 2;
    }
    @keyframes appear {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .button-logout:hover {
        color: var(--blue-600);
    }
    .button-logout:hover > .icon-close-session::v-deep > #Path_1965 {
        transform: translate(-344px, -90.5px);
    }
    .button-logout > .icon-close-session::v-deep > #Path_1965 {
        transition: var(--transition-1);
    }
    ul {
        list-style: none;
        padding: 0;
    }
    li {
        transition: var(--transition-1);
    }
    li > svg {
        transition: var(--transition-1);
    }
    li:hover svg {
        fill: var(--gray-1001);
    }
    li:hover {
        color: var(--gray-1001);
    }
    .active-tap, .active-tap svg {
        color: var(--blue-600) !important;
        fill: var(--blue-600) !important;
    }
    .center-icon {
        margin-left: -3px;
    }
    .upload-photo {
        transition: var(--transition-1);
    }
    .upload-photo > img{
        transition: var(--transition-1);
    }
    .upload-photo .icon-profile::v-deep .st0 {
        transition: var(--transition-1);
    }
    .upload-photo:hover .icon-profile::v-deep .st0 {
        fill: var(--gray-100);
    }
    .upload-photo:hover .icon-camera-container {
        background: var(--blue-600);
    }
    .upload-photo .icon-camera-container {
        transition: var(--transition-1);
    }
    .icon-profile::v-deep .st0 {
        fill: var(--gray-1001);
    }
    .icon-profile::v-deep .st1 {
        fill: #fff;
    }

    .icon-camera-container {
        width: 36px;
        height: 36px;
        border: 3px solid white;
        background: var(--blue-700);
        position: absolute;
        right: -15px;
        bottom: 15px;
        border-radius: 50%;
        z-index: 2;
    }
    .input-file {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 50px;
        cursor: pointer;
        z-index: -1;
        opacity: 0;
    }
    .profile-image {
        border-radius: 50%;
        height: 80px;
        width: 80px;
        overflow: hidden;
        object-fit: cover;
        animation: appear 0.3s;
    }
    input.input-file:hover + img{
        opacity: 0.8 !important;
    }
    .user-name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>
