<template>
    <div class="container-profile">        
        <div class="row">
            <div class="hover-blue cursor-pointer col-md-12 d-flex align-items-start align-items-md-center mb-4 px-2" @click="goBack">
                <div class="me-3 mt-2 mt-md-0">
                    <icon-arrow :size="25" style="transform: rotate(180deg);" color="var(--dark-gray-200)"/>
                </div>
                <div class="d-flex flex-column align-items-start ms-1">
                    <h6 class="title-22 color-blue-700 mb-1">{{$t("account.my_profile")}}</h6>
                    <h6 class="subtitle-16 mb-0">{{$t("account.description_my_profile")}}</h6>
                </div>
            </div>
            <div class="d-flex flex-column flex-lg-row">
                <div class="d-none d-md-flex justify-content-center justify-content-lg-start mt-3 ms-4 ps-3" style="height: max-content;">
                    <card class="card-profile">
                        <menu-profile :verifyEmployee="entityValidation" @logout="logoutModal = true" :active="selectedTap" @select="(data)=>{selectedTap = data}" />
                    </card>
                </div>
                <div class="mt-0 mt-md-5 mt-lg-4 ms-lg-5 flex-grow-1">
                    <user-form :class="{'no-click' : entityValidation }"  :verifyGroup="entityValidation" v-if="selectedTap == 'information'" @cancel="edit = false"/>
                    <change-password :class="{'no-click' : entityValidation }" v-else-if="selectedTap == 'password'" @cancel="changePassword = false"/>
                    <main-address :class="{'no-click' : entityValidation }" v-else-if="selectedTap == 'address'"/>
                </div>
            </div>
            <mobile-menu class="d-block d-md-none" :show="isOpen" @open="openMenu" @close="isOpen = false">
                <menu-profile @logout="logoutModal = true" :active="selectedTap" @select="(data)=>{selectedTap = data}"/>
            </mobile-menu>
        </div>
        <confirm-logout v-if="logoutModal" @closeModalLogout="logoutModal = false"/>
    </div>
</template>
<script>
import Card from '../../../common/components/cards/Card.vue'
import { mapGetters } from "vuex"
import ChangePassword from '../components/profile/ChangePassword.vue'
import UserForm from '../components/profile/UserForm.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'
import MainAddress from '../components/profile/MainAddress.vue'
import ConfirmLogout from '../../auth/components/ConfirmLogout.vue'
import MobileMenu from '../../../common/components/menu/MobileMenu.vue'
import MenuProfile from '../components/profile/MenuProfile.vue'

export default {
    name: "Profile",
    components:{
        Card,
        ChangePassword,
        UserForm,
        IconArrow,
        MainAddress,
        ConfirmLogout,
        MobileMenu,
        MenuProfile
    },
    data() {
        return {
            changePassword: false,
            edit: false,
            selectedTap:'information',
            logoutModal: false,
            isOpen: false
        }
    },
    computed:{
        ...mapGetters({
            userData: "auth/userData",
            addressSelected: "account/addressSelected",
            employee: "auth/employee",
            customerActive: "auth/employeeActiveCode"
        }),
        entityValidation(){
            return this.employee?.entity?.activated_employee && this.customerActive
        },
    },
    methods:{
        openMenu(){
            this.isOpen = true
        },
        goBack(){
            this.$router.go(-1)
        }
    },
}
</script>

<style scoped>
    .no-click {
        pointer-events: none;
    }
    .card-profile {
        height: auto !important;
        width: 280px;
        margin: 0;
        cursor: normal !important;
        padding-bottom: 20px;
    }
    .container-profile {
        margin: 0 auto;
    }
    .hover-blue svg {
        transition: var(--transition-1);
    }
    .hover-blue:hover svg {
        fill: var(--blue-600);
        cursor: pointer;
    }
</style>
