<template>
    <popup class="d-flex flex-column justify-content-center">
        <ul class="unstyled-list p-3 cursor-pointer position-relative" style="z-index: 2;">
            <li class="d-flex align-items-center mb-2 me-2 position-relative bg-white" @click="clickInput">
                <div class="pe-1">
                    <icon-upload :size="33" />
                </div>
                <span class="color-main-navy ms-1 mb-1 size-14">
                    {{$t("account.upload_photo")}}
                </span>
                <input type="file" name="profile" class="input-file cursor-pointer w-100 h-100" id="upload-file-input" @change="selectInput">
            </li>
            <li class="cursor-pointer d-flex align-items-center" @click="$emit('removeImage')">
                <div class="pe-2">
                    <icon-trash :size="29"/>
                </div>
                <span class="color-main-navy ms-1 mb-1 size-14">
                    {{$t("account.remove")}}
                </span>
            </li>
        </ul>
    </popup>
</template>

<script>
import Popup from '../../../../common/components/popup/Popup.vue';
import IconTrash from '../../../../common/svg/iconTrash.vue';
import IconUpload from '../../assets/svg/iconUpload.vue';

export default {
    name: "UploadProfilePic",
    components: {
        Popup,
        IconUpload,
        IconTrash
    },
    methods: {
        clickInput(){
            const input = document.querySelector("#upload-file-input")
            input.click();
        },
        selectInput($event){
            this.$emit('upload', $event.target);
        }
    },
};
</script>

<style scoped>
.input-file {
    position: absolute;
    z-index: -1;
}
input[type='file']{
    cursor: pointer !important;
}
input[type='file']:hover {
    cursor: pointer !important;
}
.icon-trash, .icon-upload {
    fill: var(--gray-1001);
}
.popup::v-deep::before {
    left: 12%;
    transform: translate(50%, -50%) rotate(320deg);
    top: 0px;
}
.popup {
    min-width: 150px;
    height: 108px;
    top: 42px !important;
    left: -40px !important;
    border-radius: 10px;
}
li:hover .icon-upload {
    fill: var(--blue-600);
}
li:hover .icon-trash {
    fill: var(--main-red);
}
</style>