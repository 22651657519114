<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 18.061 18.097">
    <g id="Group_4100" data-name="Group 4100" transform="translate(563.631 -1206.58)">
        <path id="Path_1962" data-name="Path 1962" d="M-434.231,1327.975h-1.414a.613.613,0,0,1-.678-.6.611.611,0,0,1,.685-.607h2.84a.613.613,0,0,1,.687.682q0,1.43,0,2.859a.61.61,0,0,1-.6.672.611.611,0,0,1-.6-.674c0-.476,0-.952,0-1.391l-3.206,3.208a4.162,4.162,0,0,1,.632,3.641,4.106,4.106,0,0,1-1.758,2.382,4.23,4.23,0,0,1-5.441-.718,4.225,4.225,0,0,1-.076-5.56,4.231,4.231,0,0,1,5.717-.677Zm-5.706,9.631a3.013,3.013,0,0,0,3.011-2.979,3.013,3.013,0,0,0-2.968-3.04,3.012,3.012,0,0,0-3.048,3A3.013,3.013,0,0,0-439.936,1337.606Z" transform="translate(-113.46 -114.153)"/>
        <path id="Path_1963" data-name="Path 1963" d="M-558.309,1214.206l-2.237,2.23c.278.276.567.552.843.839a.6.6,0,0,1-.261,1.005.584.584,0,0,1-.6-.17l-.829-.841c-.056.051-.1.09-.145.132-.346.345-.69.693-1.038,1.036a.6.6,0,0,1-.886.022.6.6,0,0,1,.039-.873c.383-.387.769-.771,1.175-1.177-.294-.288-.582-.565-.862-.849a.564.564,0,0,1-.141-.607.573.573,0,0,1,.456-.4.594.594,0,0,1,.563.188l.79.795,2.238-2.236a4.177,4.177,0,0,1-.639-3.671,4.1,4.1,0,0,1,1.766-2.375,4.228,4.228,0,0,1,5.425.72,4.223,4.223,0,0,1,.123,5.515A4.242,4.242,0,0,1-558.309,1214.206Zm2.5-.385a3.01,3.01,0,0,0,3.016-3.01,3.012,3.012,0,0,0-3.02-3.007,3.012,3.012,0,0,0-3,2.991A3.012,3.012,0,0,0-555.81,1213.822Z"/>
    </g>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconGender',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>