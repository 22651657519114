<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 21.334 13.085">
        <path id="Path_1949" data-name="Path 1949" d="M808.277,1168.573a.633.633,0,0,0-.653-.686c-.208-.012-.417.006-.624,0a1.307,1.307,0,0,1-.02-2.605c.18-.012.361,0,.541-.005.533-.01.756-.239.759-.8,0-.741,0-1.482,0-2.223a1.943,1.943,0,0,0-2.106-2.21q-4.786,0-9.573,0c-2.567,0-5.133,0-7.7,0a1.9,1.9,0,0,0-1.931,1.864c-.025.886-.013,1.772,0,2.658a.642.642,0,0,0,.675.709c.222.01.445-.01.666.008a1.28,1.28,0,0,1,1.152,1.266,1.293,1.293,0,0,1-1.133,1.331c-.206.02-.416,0-.624.01-.506.012-.734.246-.738.776-.005.755,0,1.51,0,2.265a1.939,1.939,0,0,0,2.086,2.189q4.288,0,8.574,0v0q4.349,0,8.7,0a1.9,1.9,0,0,0,1.95-1.844C808.3,1170.375,808.287,1169.474,808.277,1168.573Zm-15.02-3.938a1.869,1.869,0,1,1,1.853,1.941A1.916,1.916,0,0,1,793.257,1164.635Zm2.486,5.437a2.733,2.733,0,0,1-.5.429.669.669,0,0,1-.668-.948,1.819,1.819,0,0,1,.218-.318q2.335-3.06,4.673-6.119a1.881,1.881,0,0,1,.248-.292.554.554,0,0,1,.764-.035.639.639,0,0,1,.182.816,1.362,1.362,0,0,1-.19.284Q798.112,1166.984,795.743,1170.072Zm4.372.427a1.96,1.96,0,1,1,1.873-1.964A1.919,1.919,0,0,1,800.115,1170.5Z" transform="translate(-786.956 -1160.036)"/>
        <path id="Path_1950" data-name="Path 1950" d="M798.526,1165.815a.65.65,0,1,0-.69.633A.662.662,0,0,0,798.526,1165.815Z" transform="translate(-789.699 -1161.212)" fill="#a5b2b7"/>
        <path id="Path_1951" data-name="Path 1951" d="M804.669,1170.238a.653.653,0,0,0,0,1.306.653.653,0,0,0,0-1.306Z" transform="translate(-791.513 -1162.388)" fill="#a5b2b7"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconCode',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: '#a5b2b7'
    },
    
    }
}
</script>