<template>
    <main-modal @closeModal="$emit('closeModal')" :title="'auth.verify_email'">
        <div>
            <div v-if="error" class="alert alert-danger color-light-main-red d-flex align-items-center border-0 py-2" role="alert">
                {{user}}
                <icon-error :color="'var(--light-main-red)'" class="me-2"/>
                {{ $t("auth.incorrect_code") }} 
                {{$t("auth.we_have_sent_a_new_confirmation_code")}}.
            </div>
            <p class="subtitle-15 color-dark-gray-300" v-if="user"> 
                {{$t("auth.enter_the_code_sent_to_the")}} <b class="color-main-navy">{{user.data.email}}</b> {{$t("auth.before_they_pass")}} <count-down/> min.
            </p>
            <alert-warning style="padding: 2px 5px" :size="25" :show="error !== null" :title="'account.description_update_email'" :showCounter="false"/>
            <form @submit.prevent="" autocomplete="off">
                <main-input :title="'auth.code'" :id="'code'" class="mb-2" ref="code" @changeValue="(data)=>{code = data}" :readonly="readonly" @activeInput="readonly = false">
                    <icon-password :size="15" :color="'var(--dark-gray-200)'"/>
                </main-input>
                <div class="d-flex justify-content-end">
                    <main-button :title="'auth.save'" @click="verify"/>
                </div>
            </form>
        </div>
    </main-modal>
</template>

<script>
import MainInput from '../../../../common/components/inputs/MainInput.vue'
import MainButton from '../../../../common/components/buttons/MainButton.vue';
import IconPassword from '../../../auth/assets/svg/iconPassword.vue';
import IconError from '../../../../common/svg/iconError.vue';
import CountDown from '../../../../common/components/clock/CountDown.vue';
import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue';
import axios from 'axios';
import MainModal from '../../../../common/components/modals/MainModal.vue';
export default {
    components: { MainInput, MainButton, IconPassword, IconError, 
        CountDown, 
        AlertWarning,
        MainModal 
    },
    name: "NewEmail",
    data() {
        return {
            code: '',
            error: false,
            readonly: true,

        }
    },
    props:{
        userAttributes:{
            type: Object
        },
        user:{
            type: Object
        }
    },
    methods:{
        async verify(){
            let data = {
                token: this.code,
                first_name: this.user.data.first_name,
                last_name: this.user.data.last_name,
                phone: this.user.data.phone,
                email: this.user.data.email,
            }
            try {
                this.$refs.code.$v.$touch();
                if (this.$refs.code.$v.$invalid){
                    return
                }
                const response = await axios.post( process.env.VUE_APP_MM_WEB_BACKEND_ENV.replace('/api/v1/ecommerce/', this.user.updateUserAndChangeEmail), data)
                this.$store.commit('auth/SET_USER', response.data.data.user);
                this.$emit('closeModal')
            } catch (error) {
                console.log(error);
            }
        }
    },
    created(){
        console.log(this.user.updateUserAndChangeEmail);
    }
}
</script>

<style scoped>
    .modal-main-container::v-deep .modal-main{
        max-width: 600px;
    }
</style>
