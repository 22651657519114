<template>
    <div class="skeleton">
        <div class="square" :style="[{height: `${height}px`}, {width: `${width}${percentage? '%' : 'px'}`}, {borderRadius: `${borderRadius}px`}]"
        :class="classesSquare"></div>
    </div>
</template>

<script>
    export default {
        name: 'Skeleton',
        props: {
            height: {
                type: Number,
                default: 160
            },
            width:{
                type: Number,
                default: 160
            },
            percentage:{
                type: Boolean,
                default: false
            },
            borderRadius:{
                type: Number
            },
            classesSquare:{
                type: Array
            }
        }
    }
</script>

<style scoped>
    .skeleton .square {
        border-radius: 5px;
        background: rgba(130, 130, 130, 0.2);
        background: linear-gradient(to right, rgb(198 212 219 / 20%) 8%, rgb(198 212 219 / 30%) 18%, rgb(198 212 219 / 20%) 33%);
        background-size: 800px 100px;
        animation: wave-squares 1.5s infinite ease-out;
    }
    @keyframes wave-squares {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
</style>