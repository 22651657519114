<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 14.218 16.249">
        <path id="Icon_awesome-birthday-cake" data-name="Icon awesome-birthday-cake" d="M14.218,12.187c-.889,0-.992-1.016-2.364-1.016s-1.486,1.016-2.372,1.016-1-1.016-2.372-1.016-1.5,1.016-2.364,1.016c-.893,0-.99-1.016-2.372-1.016S.892,12.187,0,12.187V9.648A1.524,1.524,0,0,1,1.523,8.124h.508V3.554H4.062v4.57H6.093V3.554H8.124v4.57h2.031V3.554h2.031v4.57h.508a1.524,1.524,0,0,1,1.523,1.523Zm0,4.062H0V13.2c1.376,0,1.484-1.016,2.372-1.016S3.364,13.2,4.745,13.2c1.36,0,1.5-1.016,2.364-1.016C8,12.187,8.1,13.2,9.481,13.2s1.484-1.016,2.372-1.016c.872,0,.992,1.016,2.364,1.016ZM3.047,3.047A1.012,1.012,0,0,1,2.031,2.031c0-.984,1.016-.73,1.016-2.031.381,0,1.016.936,1.016,1.777S3.61,3.047,3.047,3.047Zm4.062,0A1.012,1.012,0,0,1,6.093,2.031c0-.984,1.016-.73,1.016-2.031C7.49,0,8.124.936,8.124,1.777S7.672,3.047,7.109,3.047Zm4.062,0a1.012,1.012,0,0,1-1.016-1.016c0-.984,1.016-.73,1.016-2.031.381,0,1.016.936,1.016,1.777S11.734,3.047,11.171,3.047Z" fill="#a5b2b7"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconDate',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>