<template>
    <div class="container-menu-mobile">
        <div class="body" :class="[show ? 'd-block' : 'd-none']">
            <div class="d-flex justify-content-end">
                <icon-close :color="'var(--dark-gray-200)'" @click.native="$emit('close')"/>
            </div>
            <slot/>
        </div>
        <button class="open-menu d-flex align-items-center justify-content-center" :class="[show ? 'arrow' : '']"  @click="$emit('open')">
            <icon-menu :color="'white'"/>
        </button>
    </div>
</template>

<script>
import iconMenu from '../../../modules/layout/assets/svg/iconMenu.vue'
import IconClose from '../../svg/iconClose.vue'
export default {
    components: { iconMenu, IconClose },
    name: "MobileMenu",
    props:{
        show:{
            type: Boolean
        }
    }
}
</script>

<style scoped>
    .container-menu-mobile{
        position: fixed;
        bottom: 15px;
        left: 15px;
    }
    .open-menu{
        width: 50px;
        height: 50px;
        background: var(--blue-600);
        border: none;
        border-radius: 50%;
        z-index: 1000;
        box-shadow: 2px 3px 9px 0px #909da41a;

    }
    .body{
        margin-bottom: 10px;
        max-width: 300px;
        background: white;
        padding: 30px;
        left: 30px;
        bottom: 0;
        border-radius: 20px;
        box-shadow: 2px 3px 9px 0px #909da41a;
        animation: open-menu 0.3s;
    }
    .arrow::after{
        content: '';
        position: absolute;
        bottom: 22px;
        left: 25px;
        border-bottom: 30px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        animation: open-menu 1s;
    }
    @keyframes open-menu {
        from{
            opacity: 0;
            transform: scale(0.5);
        }to{
            opacity: 1;
            transform: scale(1);

        }
    }
</style>