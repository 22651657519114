<template>
    <icon-base class="icon-camera" :width="size + 'px'" :fill="color" viewBox="0 0 16.237 13.238">
        <path id="Camera_icon-2" data-name="Camera icon" d="M267.584,237.1a2.51,2.51,0,1,0,2.507,2.509A2.51,2.51,0,0,0,267.584,237.1Zm6.643-2.425H272.6a1.425,1.425,0,0,1-1.4-1.235,1.425,1.425,0,0,0-1.415-1.277h-4.5a1.423,1.423,0,0,0-1.413,1.277,1.428,1.428,0,0,1-1.408,1.235h-1.63a1.423,1.423,0,0,0-1.42,1.417v7.892a1.423,1.423,0,0,0,1.42,1.418h13.4a1.423,1.423,0,0,0,1.42-1.418v-7.892A1.423,1.423,0,0,0,274.228,234.671Zm-6.643,9.085a4.149,4.149,0,1,1,4.149-4.151A4.15,4.15,0,0,1,267.584,243.757Zm6.011-6.327a.568.568,0,1,1,.567-.569A.57.57,0,0,1,273.595,237.43Z" transform="translate(-259.41 -232.16)" fill="#fff"/>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconCamera',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>