<template>
<div>
    <div class="mb-2">
        <h4 class="d-flex align-items-center mb-2 weight-600 color-dark-main-navy-800">{{$t("account.change_my_password")}} 
            <a class="d-flex align-items-center size-15 ms-3 weight-500 hover-red" href="#" @click.prevent="focusName">
                <icon-edit class="me-2" :size="15" :color="'var(--blue-600)'"/>
                {{$t('checkout.edit')}}
            </a>
        </h4>
        <p>{{$t("account.to_change_your_password")}}</p>
    </div>
    <form @submit.prevent="" autocomplete="off" class="mt-4">
        <alert-warning :show="doNotMatch" :showCounter="false" :size="25" :title="'common.passwords_do_not_match'" class="mt-2 mb-2 p-0"/>
        <alert-warning :show="incorrectPassword" :showCounter="false" :size="25" :title="'account.wrong_current_password'" class="mt-2 mb-2 p-0"/>
        <div class="row animation-opacity ms-0 ms-md-3">
            <div class="mb-1" :class="$i18n.locale == 'es' ? 'col-md-7 col-xl-6 col-xxl-5':'col-md-6 col-xl-5'">
                <main-input :value="currentPassword" :title="$t('account.current_password')" :id="'password'" :type="'text'" ref="currentPassword" @changeValue="(data)=>{currentPassword = data}" :readonly="readonly" 
                    @activeInput="readonly = false" :fixedType="'password'" :placeholder="'auth.enter_your_password'" :min="6">
                        <template #forgotPassword>
                            <span @click="showForgotPassword = true" class="forgot-password cursor-pointer hover-underline ms-auto size-14 color-blue-600">
                                {{$t("account.forgot_password")}}
                            </span>
                        </template>
                        <icon-password :size="12" :color="'var(--dark-gray-200)'"/>
                </main-input>
            </div>
            <div :class="$i18n.locale == 'es' ? 'col-md-3':'col-md-6 col-xl-5'" />
            <div class="col-md-6 col-xl-5 mb-3 mt-2 mt-md-3">
                <main-input :value="newPassword" :title="$t('account.new_password')" id="new-password" customValidate="new-password" type="text" ref="newPassword" @changeValue="(data)=>{newPassword = data}" :readonly="readonly" @activeInput="readonly = false"
                    placeholder="auth.enter_your_password" :fixedType="'password'" :min="6">
                        <icon-password :size="12" color="var(--dark-gray-200)"/>
                </main-input>     
            </div>
            <div class="col-md-6 col-xl-5 mb-3 mt-2 mt-md-3">
                <main-input :value="confirmNewPassword" :title="$t('account.confirm_new_password')" id="confirm-new-password" type="text" ref="confirmNewPassword" @changeValue="(data)=>{confirmNewPassword = data}" :readonly="readonly" @activeInput="readonly = false"
                    :placeholder="'auth.enter_your_password'" :fixedType="'password'" :min="6">
                        <icon-password :size="12" color="var(--dark-gray-200)"/>
                </main-input>      
            </div>
            <div class="col-md-10 col-lg-12 col-xl-10 d-flex justify-content-end">
                <main-button :id="'save'" :title="'account.save_changes'" @click="changePassword" :disabled="changed"/>
            </div>
        </div>
        <main-modal v-if="showForgotPassword" :title="$t('auth.restore_password')" @closeModal="showForgotPassword = false" >
            <forgot-password :inProfile="true" :email="user.email" :step2="true" @closeModal="showForgotPassword = false" />
        </main-modal>
    </form>   
</div>
</template>
<script>
import MainButton from '../../../../common/components/buttons/MainButton.vue'
import MainInput from '../../../../common/components/inputs/MainInput.vue'
import IconPassword from '../../../auth/assets/svg/iconPassword.vue'
import { Auth } from 'aws-amplify';
import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue'
import ForgotPassword from '../../../auth/components/ForgotPassword.vue';
import { mapGetters } from "vuex";
import MainModal from '../../../../common/components/modals/MainModal.vue';
import IconEdit from '../../../checkout/assets/svg/iconEdit.vue'

export default {
    components: { MainInput, MainButton, IconPassword, IconEdit, AlertWarning, ForgotPassword, MainModal },
    name: "ChangePassword",
    data() {
        return {
            showCurrent: false,
            showConfirm: false,
            showNew: false,
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            doNotMatch: false,
            readonly: true,
            incorrectPassword: false,
            showForgotPassword: false,
            focusCounter: null
        }
    },
    computed: {
        ...mapGetters({user:"auth/userData"}),
        changed(){
            return this.currentPassword == "" && this.newPassword === "" && this.confirmNewPassword === ""; 
        }
    },
    watch: {
        confirmNewPassword(){
            if(this.newPassword === this.confirmNewPassword){
                this.doNotMatch = false
            }
        },
        newPassword(){
            if(this.newPassword === this.confirmNewPassword){
                this.doNotMatch = false
            }
        }
    },
    methods: {
        async changePassword(){
            this.$refs.currentPassword.$v.$touch();
            this.$refs.newPassword.$v.$touch();
            this.$refs.confirmNewPassword.$v.$touch();
            if (this.$refs.currentPassword.$v.$invalid || this.$refs.newPassword.$v.$invalid || this.$refs.confirmNewPassword.$v.$invalid){
                return
            }
            if(this.newPassword != this.confirmNewPassword){
                this.doNotMatch = true;
                return
            }
            try {
                const user = await Auth.currentAuthenticatedUser()
                await Auth.changePassword(user, this.currentPassword, this.confirmNewPassword)
                this.currentPassword = ""
                this.newPassword = ""
                this.confirmNewPassword = ""
                this.$refs.currentPassword.$v.$reset();
                this.$refs.newPassword.$v.$reset();
                this.$refs.confirmNewPassword.$v.$reset();
            } catch (error) {
                this.incorrectPassword = true
                console.log(error);
            }finally{
                setTimeout(() => {
                    this.$refs.newPassword.$v.$reset();
                }, 100);
            }
        },
        focusName(){
            this.readonly = false;
            this.focusCounter = setImmediate(() => {
                this.$refs.currentPassword.$el.querySelector(".input-group").querySelector("input").focus();
            });
        }
    },
    destroyed(){
        clearImmediate(this.focusCounter);
    } 
}
</script>
<style scoped>
    .modal-main-container::v-deep > .modal-main {
        width: 440px;
        padding-bottom: 0 !important;
    }
    .password {
        position: relative;
    }
    .eye-container {
        position: absolute;
        right: 15px;
        top: 52%;
        fill: #A5B2B7;
        user-select: none;
    }
    .eye-container:hover svg{
        fill: #c40316 !important;
        cursor: pointer;
    }
    .forgot-password:hover {
        color: var(--main-red) !important;
        
    }    
    .hover-red > svg {
        transition: var(--transition-1);
    }
</style>