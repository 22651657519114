<template>
    <div v-if="getDefaultAddress">
        <div>
            <h4 class="mb-2 weight-600 d-flex align-items-center">{{$t("account.default_address")}}
                <a @click.prevent="edit" class="size-15 weight-500 ms-3 d-flex align-items-center hover-red" href="#">
                    <icon-edit class="me-2" :size="15" :color="'var(--blue-600)'"/>{{$t('checkout.edit')}}
                </a>
            </h4>
            <p>{{$t("account.description_default_address")}} <router-link to="/account/addresses">{{$t("account.my_addresses")}}</router-link>.</p>
        </div>        
        <div class="row animation-opacity ms-0 ms-md-3">
            <div class="col-md-6 mb-4">
                <div class="d-flex align-items-center mb-2">
                    <span class="title-15 mb-0 text-capitalize">{{getDefaultAddress.name | textFormat}}</span>
                    <icon-star :size="15" class="ms-2"/>
                </div>
                <div class="d-flex flex-column">
                    <span class="title-15 mb-0 weight-300">Tel:<span class="ms-2 weight-300">{{getDefaultAddress.phone | phoneFormat}}</span></span>
                    <span class="text-limited weight-300 title-15 mb-0 text-capitalize">{{getDefaultAddress.address_line2 | textFormat}}, {{getDefaultAddress.city.city | textFormat}}</span>
                    <span class="text-limited title-15 mb-0 text-capitalize">Ref: <span class="weight-300">{{getDefaultAddress.reference | textFormat}}</span></span>
                </div>
            </div>
            <div class="col-md-6 mb-4 d-flex align-items-end justify-content-end px-4">
                <ul class="nav nav-pills">
                    <li class="nav-item" v-for="(style, index) in mapStyles" :key="index">
                        <div class="d-flex align-items-center">
                            <a href="#" @click.prevent="styleSelected = index" :class="{'color-blue-600 weight-600': index === styleSelected }" class="option size-14 color-dark-gray-200">
                            {{ index }}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-12">
                <MglMap v-if="getDefaultAddress" style="height: 300px; border-radius: 10px; overflow: hidden;" :center="[getDefaultAddress.longitud, getDefaultAddress.latitud]" 
                :accessToken="accessToken" :zoom="14" :dragRotate="false" @load="onMapLoaded" 
                :mapStyle="mapStyles[styleSelected]">
                    <MglMarker  style="cursor: pointer" :coordinates="[getDefaultAddress.longitud, getDefaultAddress.latitud]" :draggable="false"  color="#c40316">
                    </MglMarker>
                    <MglNavigationControl position="top-right"/>
                </MglMap>
            </div>
            <create-address v-if="showAddressModal" @closeModal="showAddressModal = false" :addressEditData="addressEditData"/>
        </div>
    </div>
    <without-results v-else @click="goToAddress" class="mt-5" description="account.description_you_do_not_have_a_default_address" title="account.you_do_not_have_a_default_address" titleButton="account.manage_addresses">
        <template #main-icon>
            <icon-delete-address :size="80"/>
        </template>
    </without-results>
</template>

<script>
import IconEdit from "../../../checkout/assets/svg/iconEdit.vue"
import { MglMap, MglMarker, MglNavigationControl } from 'v-mapbox'
import Mapbox from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css'
import { mapGetters } from "vuex"
import IconStar from '../../../../common/svg/iconStar.vue';
import CreateAddress from '../addresses/modals/CreateAddress.vue';
import IconDeleteAddress from '../../assets/svg/iconDeleteAddress.vue';
import WithoutResults from '../../../../common/components/modals/WithoutResults.vue';

export default {
    name: "MainAddress",
    components: {
        IconEdit,
        MglMap,
        MglMarker,
        MglNavigationControl,
        IconStar,
        CreateAddress,
        IconDeleteAddress,
        WithoutResults
    },
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPBOX_KEY,
            mapStyles: {
                Mapa: "mapbox://styles/fbernabe/clasqq2sj002w14qp6xg4dkus",
                Satellite: "mapbox://styles/fbernabe/clbcjvwmq002016nkqy7vxtzt",
            },
            showAddressModal: false,
            styleSelected: "Mapa",
            addressEditData: null
        }
    },
    computed:{
        ...mapGetters({
            addresses:"account/getAddresses"
        }),
        getDefaultAddress(){
            let defaultAddress = this.addresses.find(address => address.default);
            return defaultAddress ? defaultAddress : false;
        },
    },
    methods:{
        onMapLoaded(){
            this.mapbox = Mapbox;
        },
        edit(){
            this.addressEditData = this.getDefaultAddress;
            this.showAddressModal = true;
        },
        goToAddress(){
            this.$router.replace({path: '/account/addresses'}).catch(() => {})
        }
    }
}
</script>
<style scoped>
    .without-results::v-deep > p {
        max-width: 400px;
    }
    li.nav-item:not(:last-child) div .option {
        border-right: 1.5px solid var(--gray-100);
        padding-right: .5rem;
        margin-right: .5rem;
    }
    .hover-red > svg {
        transition: var(--transition-1);
    }
</style>
